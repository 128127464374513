import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";



const NavbarTwo = (props) => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const [scrollAnimation, setScrollAnimation] = useState(false);
  
  useEffect(() => {
    // alert('hello')
  }, [])
  
  const variant = props.variant
  // console.log(props)
  var colPrimary = 'red';
  var colSecondary = 'white';
  switch (variant) {
    case 1:
      colPrimary = 'red';
      colSecondary = 'white';
      break; // Prevents fall-through
    case 2:
      colPrimary = 'white';
      colSecondary = 'red';
      break; // Prevents fall-through
    case 3:
      colPrimary = 'black';
      colSecondary = 'white';
      break; // Ensures this is the last executed case
  }
  
  /*
    THIS IS FOR NAVBAR SCROLL ANIMATION
  */
  window.addEventListener("scroll", () => {
    window.scrollY > 50 ? setScrollAnimation(true) : setScrollAnimation(false);
  });

  /*
     FOR OPENING SIDE BAR
  */

  const openSideBar = () => {
    setOpen(!open);
    setActive(!active);
  };

  /*
     FOR CLOSING SIDEBAR AND ADDING ACTIVE COLOR IN NAV LINK
  */

  const closeNavFun = (value) => {
    setActive(false);
    setOpen(false);
    setActiveLink(value);
    setActiveLink(value);
    window.scrollTo({
      top: 0,
    });
  };

  const navClickFunc = (value) => {
    setActiveLink(value);
    setOpen(false);
    setActive(false);
    window.scrollTo({
      top: 0,
    });
  };

  const [locationsDropdownOpen, setLocationsDropdownOpen] = useState(false);

  const toggleLocationsDropdown = () => {
    setLocationsDropdownOpen(!locationsDropdownOpen);
  };
  


  const navItems = [
    "home",
    "about",
    "AJFC",
    "contact",
    "hospitality",
    "sponsors"
  ];

  const navItemsSmall = [
    "home",
    "about",
    "AJFC",
    "store ",
    "contact",
    "hospitality",
    "sponsors"
  ];

  return (
    <nav
      className={`navbar md:px-[40px] px-[20px] z-20 w-full h-[80px] flex items-center justify-between  absolute top-0 left-0 ${
        scrollAnimation ? "nav-scroll shadow " : " "
      }`}
    >
      <div className="logo py-3">
        <Link
          to="/"
          className="flex items-center flex-wrap gap-x-8"
          onClick={() => navClickFunc("img")}
        >
          <img
            src="/image/logo.png"
            className="w-[150px] h-auto object-cover"
            alt=""
          />
          <img
            src="/image/sublogo-dark.png"
            className="w-[55px] h-auto object-cover"
            alt=""
          />
        </Link>
      </div>
      {/* LARGE SCREEN NAV-LINKS */}
      <div className="links_section lg:block hidden">
        <ul className="list-none flex items-center xl:gap-x-8 gap-x-6">
          {navItems.map((element, index) => {
            return (
              <li key={index}>
                <Link
                  to={`/${element === "home" ? "" : element}`}
                  className={`uppercase text-${colPrimary} text-[15px] pb-2 nav-two-link font-primary font-semibold relative before:absolute before:w-[0%] before:h-[2px] before:left-0 before:bottom-0 before:transition before:duration-300 transition duration-500  hover:before:bg-${colPrimary} hover:before:w-[100%] ${
                    activeLink === element ? `before:bg-${colPrimary}` : `text-${colPrimary}`
                  }`}
                  onClick={() => navClickFunc([element, "top"])}
                >
                  {element}
                </Link>
              </li>
            );
                
          })}
        <li className={'flex relative'}>
          <button
            onClick={toggleLocationsDropdown}
            className={`uppercase text-${colPrimary} text-[15px] flex font-primary font-semibold relative transition duration-500 before:absolute before:w-[0%] before:h-[2px] before:left-0 before:bottom-0 before:transition before:duration-300 transition duration-500  hover:before:bg-${colPrimary} hover:before:w-[100%]`}
            // className={`uppercase flex gap-[8px] text-white text-[15px] pb-2 font-primary font-semibold relative transition duration-500 hover:text-red margin[0] padding[0]`}
          >
            Locations <span className="ml-2 flex margin[50px] translate-y-[2px]">{locationsDropdownOpen ? <SlArrowDown />:<SlArrowUp/>}</span>
          </button>
          {locationsDropdownOpen && (
            <div className="absolute top-full mt-2 left-0 bg-white shadow-md  z-50">
              <ul className="text-black">
                <li className="px-4 py-2 hover:bg-gray-200">
                  <Link
                    to="/london"
                    className={` text-[15px] hover:text-red pb-2 nav-two-link font-primary font-semibold relative before:absolute before:w-[0%] before:h-[2px] before:left-0 before:bottom-0 before:transition before:duration-300 transition duration-500  hover:before:bg-${colPrimary} hover:before:w-[100%] `}

                  >
                    London
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-200">
                  <Link
                    to="/manchester"
                    className={" text-[15px] hover:text-red pb-2 nav-two-link font-primary font-semibold relative before:absolute before:w-[0%] before:h-[2px] before:left-0 before:bottom-0 before:transition before:duration-300 transition duration-500  hover:before:bg-white hover:before:w-[100%] "}

                  >
                    Manchester
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>
        <Link
         to="/store"
         >
          <li>
            <button className={`uppercase font-primary text-${colSecondary} bg-${colPrimary} px-6 py-3 font-semibold flex items-center transition duration-300 hover:scale-105`}>
              STORE{" "}
              {/* <svg className="ms-1 text-[20px]" width="22" height="28" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.85286 0.00943554C1.27658 0.108098 0.760527 0.447677 0.418233 0.95347C0.0759396 1.45926 -0.0645587 2.08984 0.0276463 2.70648C0.119851 3.32311 0.437207 3.8753 0.909898 4.24157C1.38259 4.60783 1.9719 4.75817 2.54818 4.65951H9.06679L9.45791 5.82202L11.2397 11.6346L13.0214 17.4472C13.1952 18.0517 13.934 18.6097 14.499 18.6097H29.7091C30.3175 18.6097 31.0128 18.0517 31.1866 17.4472L34.7067 5.82202C34.8805 5.21751 34.6198 4.65951 34.0113 4.65951H14.7163L13.0649 1.31146C12.889 0.92717 12.6174 0.602276 12.28 0.372548C11.9427 0.142821 11.5526 0.017171 11.1527 0.00943554L2.46126 0.00943554C2.33115 -0.00314518 2.20025 -0.00314518 2.07014 0.00943554C1.98331 0.00385042 1.89624 0.00385042 1.8094 0.00943554L1.85286 0.00943554ZM15.5854 23.2598C14.3686 23.2598 13.4125 24.2828 13.4125 25.5848C13.4125 26.8868 14.3686 27.9099 15.5854 27.9099C16.8022 27.9099 17.7583 26.8868 17.7583 25.5848C17.7583 24.2828 16.8022 23.2598 15.5854 23.2598ZM28.6226 23.2598C27.4058 23.2598 26.4498 24.2828 26.4498 25.5848C26.4498 26.8868 27.4058 27.9099 28.6226 27.9099C29.8394 27.9099 30.7955 26.8868 30.7955 25.5848C30.7955 24.2828 29.8394 23.2598 28.6226 23.2598Z" fill="black"/>
                  </svg> */}
              <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px] " />
            </button>
          </li>
          </Link>
        </ul>
      </div>
      {/* ANIMATED HAMBURGER */}
      <div className="lg:hidden">
        <button
          type="button"
          className="hamburger flex flex-col md:gap-y-[7px] gap-y-[6px]"
          onClick={openSideBar}
        >
          <span
            className={`md:w-[30px] w-[25px] md:h-[2.4px] h-[2.2px] bg-[#43525B] rounded-full transform transition duration-500 ease-in-out ${
              open
                ? "rotate-45 md:translate-y-[0.5rem] translate-y-[0.6rem]"
                : ""
            }`}
          ></span>
          <span
            className={`md:w-[30px] w-[25px] md:h-[2.4px] h-[2.4px] bg-[#43525B] rounded-full transform transition duration-500 ease-in-out ${
              open ? " opacity-0" : ""
            }`}
          ></span>
          <span
            className={`md:w-[30px] w-[25px] md:h-[2.4px] h-[2.2px] bg-[#43525B] rounded-full transform transition duration-500 ease-in-out ${
              open
                ? " -rotate-45 md:translate-y-[-.6rem] translate-y-[-.45rem]"
                : ""
            }`}
          ></span>
        </button>
      </div>

      {/* SMALL SCREEN NAV-LINKS */}
      <div
        className={`mobile_menu items-centre overscroll-contain lg:hidden block absolute top-0 bg-white shadow-lg  w-[90%] h-screen z-50 duration-300 bg-blue-500 px-4 pt-4 left-[50%] translate-x-[-50%] ${
          active
            ? "top-[4rem] max-h-[550px] opacity-95 "
            : "top-[4rem] max-h-[0px] opacity-0"
        } `}
      >
        {/* LNIKS SECTION */}
        <div className="py-5 m-auto p w-full items-start justify-start">
          <ul className="list-none flex items-center justify-center flex-col padding w-full divide-y divide-blue-200 ">
            {navItemsSmall.map((element, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/${element === "home" ? "" : element}`}
                    className={`uppercase text-[30px] font-primary font-semibold transition duration-500 hover:text-sky-500${
                      activeLink === element ? "text-red" : "text-black"
                    }`}
                    onClick={() => navClickFunc(element)}
                  >
                    {element}
                  </Link>
                </li>
              );
            })}
            <li className={''}>
              <button
                onClick={toggleLocationsDropdown}
                className={"uppercase text-[30px] font-primary font-semibold transition duration-500 hover:text-sky-500 flex"}                // className={`uppercase flex gap-[8px] text-white text-[15px] pb-2 font-primary font-semibold relative transition duration-500 hover:text-red margin[0] padding[0]`}
              >
                Locations <span className="ml-2 flex align-centre translate-y-[6px]">{locationsDropdownOpen ? <SlArrowDown />:<SlArrowUp/>}</span>
              </button>
            </li>
              {locationsDropdownOpen && (
                // <div className="absolute top-full mt-2 left-0 bg-white shadow-md  z-50">
                  <ul className="text-black transform transition duration-500 ease-in-out text-centre">
                    <li className="hover:bg-gray-200">
                      <Link
                        to="/london"
                        className={"uppercase text-[30px] text-[gray] font-primary font-medium color[red] transition duration-500 hover:text-sky-500 flex transform transition duration-500 ease-in-out"}                // className={`uppercase flex gap-[8px] text-white text-[15px] pb-2 font-primary font-semibold relative transition duration-500 hover:text-red margin[0] padding[0]`}
                      >
                        London
                      </Link>
                    </li>
                    <li className=" hover:bg-gray-200">
                      <Link
                        to="/manchester"
                        className={"uppercase text-[30px] translate-y-[-10px] text-[gray] font-primary font-semibold color[red] transition duration-500 hover:text-sky-500 flex transform transition duration-500 ease-in-out"}                // className={`uppercase flex gap-[8px] text-white text-[15px] pb-2 font-primary font-semibold relative transition duration-500 hover:text-red margin[0] padding[0]`}
                      >
                        Manchester
                      </Link>
                    </li>
                  </ul>
                // </div>
              )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarTwo;
