import React from "react";
import StoreHeader from "../element/StoreSection/StoreHeader";
import KitSection from "../element/StoreSection/KitSection";
import Product from "../element/StoreSection/Product";
import NavbarTwo from "../NavFooter/NavbarTwo";

const Store = () => {
  return (
    <>
      <NavbarTwo variant={3}/>
      {/* <StoreHeader /> */}
      <KitSection />
      <Product />
    </>
  );
};

export default Store;
