import React from "react";
import NavbarTwo from "../NavFooter/NavbarTwo";
import Header from "../element/ManchesterSection/Header";
import Academy from "../element/HospitalitySection/Academy";
import Hub from "../element/HospitalitySection/Hub";
import Feature from "../element/HospitalitySection/Feature";
import Host from "../element/HospitalitySection/Host";
import AboutManchester from "../element/ManchesterSection/AboutManchester";
import OneToOne from "../element/ManchesterSection/OneToOne";
import Contact from "../element/HomeSection/Contact";

const Manchester = () => {
  return (
    <>
      <NavbarTwo variant={2}/>
      <Header />
      <AboutManchester />
      <OneToOne />
      <Contact location={'manchester'} enquiry={'general'}/>    </>
  );
};

export default Manchester;
