import React from "react";

const Category = ({scrollToTeam}) => {
  const content = [
    "MEN",
    "U15",
    "U13",
    "U12",
    "U11",
    "U10",
    'U9',
    "U8",
    "U7",
  ];

  return (
    <main className="py-[60px] md:px-[40px] px-[20px] bg-[#F6F5F8] sm:block">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-10 gap-6">
        {content.map((element, index) => {
          
          return (
            <button
              onClick={() =>
                scrollToTeam(index)
              }
              key={index}
              className="max-w-[250px] h-[75px] bg-red text-white transition duration-300 hover:bg-[#9c081c] hover:text-white focus:bg-[#9c081c] focus:text-white flex items-center justify-center text-[30px] font-semibold"
            >
              {element}
            </button>
          );
        })}
      </div>
    </main>
  );
};

export default Category;
