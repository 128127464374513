import React from "react";

const SocialModel = () => {
  return (
    <div>


      <div className="relative z-10">
          <img src="/image/about/shape-one.png" className="lg:max-w-[400px] md:max-w-[600px] max-w-[320px] md:h-auto  h-[690px] absolute top-0 right-0 z-[-1]" alt="" />
          <img src="/image/about/shape-two.png" className="lg:max-w-[450px] md:max-w-[650px] max-w-[360px] md:h-auto  h-[710px] absolute top-0 right-0 z-[-2]" alt="" />
        <div
          className="w-full py-[60px] bg-cover md:px-[40px] px-[20px]"
          
        >
          <div className="club_section lg:max-w-[1100px] w-full mx-auto lg:grid grid-cols-12">
            <div className="club_img col-span-7 lg:block hidden">
              <img
                src="/image/about/social-img.png"
                className="sm:max-w-[550px] max-w-[500px] h-auto "
                alt=""
              />
            </div>
            <div className="club_content col-span-5 lg:flex flex-col justify-center items-center">
              <div>
                <span className="red-badge mb-2">community outreach</span>
                <h2 className="mb-4">Social Model</h2>
                <p className="text-[15px] text-gray font-medium mb-2">
                Our initial focus is on the Nottingham boroughs that are “hot spots”
            for social deprivation <br /> Gang involvement, violence,
            crime, re-offending and unemployment blight too many lives and young
            people, their families and communities suffer from a continuous
            chain of entrenched lifestyle, offending and social, health and
            economic problems.
                </p>
                <p className=" text-[15px] text-gray font-medium mb-2 ">
                We will work with young people to break that chain with positivity.
                </p>
                {/* <button type="button" className="btn-primary-outline mt-8">
                  JOIN US{" "}
                  <MdOutlineKeyboardDoubleArrowRight className="ms-3 text-[20px]" />
                </button> */}
              </div>
            </div>
            <div className="club_img lg:hidden block  mt-8 ">
              {/* <img
                src="/image/about/mission-img.png"
                className="max-w-[500px] w-full h-auto"
                alt=""
              /> */}
              <img
                src="/image/about/social-img.png"
                className="max-w-[500px] w-full h-auto"
                alt=""
              />
            </div>
          </div>
        </div>
    </div>
    
    </div>)
};

export default SocialModel;
