import React from "react";
import { useState, useEffect } from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const Product = () => {
  const [productContent, setProductContent] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(20); // Initial display limit


  useEffect(() => {
    // Adjust the path if your JSON file is located elsewhere
    fetch('/image/store/store.json')
      .then((response) => response.json())
      .then((data) => setProductContent(data))
      .catch((error) => console.error('Error fetching product data:', error));
  }, []);

  // Function to truncate titles longer than 28 characters
  const truncateTitle = (title) => {
    return title.length > 38 ? title.substring(0, 35) + '...' : title;
  };

  // Function to increase the number of displayed products
  const viewMore = () => {
    setDisplayLimit(currentLimit => currentLimit + 20); // Increase by 20 or adjust as needed
    // Or setDisplayLimit(productContent.length); to display all remaining products
  };

  // const productContent = [
  //   {
  //     img: "/image/store/product/img-1.png",
  //     title: "Men’s Training 1/4 Zip",
  //     price: "£39",
  //   },
  //   {
  //     img: "/image/store/product/img-2.png",
  //     title: "sports Polo Tshirt",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-3.png",
  //     title: "air tag Jersey",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-4.png",
  //     title: "full sleeve jersey ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-1.png",
  //     title: "Men’s Sports Jersey ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-2.png",
  //     title: "sports Polo Tshirt ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-3.png",
  //     title: "air tag Jersey ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-4.png",
  //     title: "full sleeve jersey  ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-1.png",
  //     title: "Men’s Sports Jersey ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-2.png",
  //     title: "sports Polo Tshirt ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-3.png",
  //     title: "air tag Jersey",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-4.png",
  //     title: "full sleeve jersey  ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-1.png",
  //     title: "Men’s Sports Jersey  ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-2.png",
  //     title: "sports Polo Tshirt  ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-3.png",
  //     title: "air tag Jersey  ",
  //     price: "$70.00 - $85.00",
  //   },
  //   {
  //     img: "/image/store/product/img-4.png",
  //     title: "full sleeve jersey   ",
  //     price: "$70.00 - $85.00",
  //   },
  // ];

  return (
    <div className="product-section md:px-[40px] px-[20px] pt-[40px] pb-[60px]">
      <div className="flex items-center justify-end">
        <select>
          <option value="" selected>
            SORT BY LATEST
          </option>
          <option value="" selected>
            Lowest
          </option>
          <option value="" selected>
            Highest
          </option>
          <option value="" selected>
            Price A to Z
          </option>
        </select>
      </div>
      <div className="products grid lg:grid-cols-4 grid-cols-2 gap-x-4 gap-y-8 mt-[30px]">
        {productContent.slice(0, displayLimit).map((element, index) => {
          return (
            <div className="  cursor-pointer overflow-hidden" key={index}>
              <div className=" group relative overflow-hidden flex items-center justify-center p-4 rounded-[20px] bg-[#F6F5F8] z-10">
                <div className="animation w-[100px] h-[350px] bg-red absolute  top-[-3rem] left-[50%] skew-x-[-15deg] translate-x-[-50%] z-[-1] opacity-0 transition duration-300 group-hover:opacity-100"></div>
                <img
                  // src="/image/store/product/img-1.png"
                  src={'/image/store/storeImages/'+index+'.png'}
                  className="md:h-[230px] h-[150px] "
                  alt=""
                />
              </div>
              <div className="price-section mt-4">
                <h4 className=" lg:text-[20px] sm:text-[22px] text-[18px] font-primary uppercase font-bold text-black ">
                  {truncateTitle(element.title.replace('AJ Sport London', ''))}
                </h4>
                <p className="font-primary font-medium text-red ">
                  {element.price}
                </p>
                <a href={element.url} target="_blank">
                <button className="uppercase font-primary w-full block py-2 font-medium mt-3  text-white bg-black transition duration-300 hover:bg-red hover:scale-105">
                  <span className="flex items-center justify-center">
                    {" "}
                    buy now{" "}
                    <MdOutlineKeyboardDoubleArrowRight className="inline text-[20px]" />
                  </span>
                </button>

                </a>
              </div>
            </div>
          );
        })}
      </div>
      {/* Only show the "View more" button if there are more products to show */}
      {displayLimit < productContent.length && (
        <div className="flex items-center justify-center mt-8 w-full">
          <button onClick={viewMore} className="btn-primary">
            View more <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Product;
