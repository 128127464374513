import React from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const Hub = () => {
  return (
    <div className="md:py-[60px] py-[40px] md:px-[40px] px-[20px]">
      <section className="lg:grid grid-cols-12">
        {/* CONTENT SECTION */}
        <div className="col-span-4 lg:mb-0 mb-8">
          <div className="content-section">
            <span className="mb-4 red-badge">Locate us</span>
            <h2 className="mb-5">OUR HUB</h2>
            <div className="lg:max-w-[500px]">
              <p className="text-gray text-[16px] font-normal mb-3">
              At AJ Sport Nottingham, our hub serves as a vital community cornerstone, not just for nurturing sporting talent but also as a gathering place that brings diverse groups together, fostering a sense of unity and cooperation across Nottingham.

              </p>
              <p className="text-gray text-[16px] font-normal mb-3">
              By hosting events and activities that go beyond sports, we encourage community engagement, provide platforms for cultural exchanges, and offer educational programs that benefit all ages.


              </p>
              <p className="text-gray text-[16px] font-normal mb-3">
              This inclusive environment helps to strengthen community bonds and promotes a shared sense of responsibility and pride. As a result, our academy plays a pivotal role in making Nottingham not only a hub for aspiring athletes but also a flourishing community where everyone can thrive together in harmony and mutual support.
              </p>


              <button className="btn-primary">
                Learn more{" "}
                <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" />
              </button>
            </div>
          </div>
        </div>
        {/* IMAGE SECTION */}
        <div className="col-span-8">
          <div className="map-section lg:py-0 py-[40px] w-full relative z-10 overflow-hidden">
            <iframe
              className="xl:max-w-[550px] lg:max-w-[500px] w-full h-[500px] mx-auto  "
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2404.584509756152!2d-1.2139503533693938!3d52.937904921379534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879e9e42a7d578f%3A0xce512b361c3aa381!2sSheila%20Roper%20Community%20Centre!5e0!3m2!1sen!2sus!4v1712942879667!5m2!1sen!2sus" 
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            <div className="story_content max-w-[300px] absolute lg:bottom-0 bottom-[2.5rem] lg:right-[5rem] right-[0rem] bg-white py-3 px-6">
              <h4 className="font-primary text-black font-medium mb-3 uppercase text-[20px]">
                tenants hall cl, lenton abbey, nottingham ng29 2rw
              </h4>
              <Link to={'/contact'}>
              <button className="btn-primary">contact us</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hub;
