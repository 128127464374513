import React from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const OneToOne = () => {
  return (
    <div className="relative z-10">
        <img src="/image/about/shape-one.png" className="lg:max-w-[650px] md:max-w-[600px] max-w-[320px] md:h-auto h-[500px] absolute top-0 right-0 z-[-1]" alt="" />
        <img src="/image/about/shape-two.png" className="lg:max-w-[700px] md:max-w-[650px] max-w-[360px] md:h-auto h-[550px] absolute top-0 right-0 z-[-2]" alt="" />
      <div
        className="w-full py-[100px] bg-cover md:px-[40px] px-[20px]"
        
      >
        <div className="club_section lg:max-w-[1100px] w-full mx-auto lg:grid grid-cols-12">
          <div className="club_img col-span-7 lg:block hidden">
            <img
              src="/image/manchester/join-img.png"
              className="sm:max-w-[550px] max-w-[500px] h-auto "
              alt=""
            />
          </div>
          
          <div className="club_content col-span-5 lg:flex flex-col justify-center items-center">
            <div>
              <span className="red-badge mb-2">our mission</span>
              <h2 className="mb-4">THE MISSION</h2>
            <div className="club_img block lg:hidden pb-[20px]  mt-8 ">
              <img
                src="/image/manchester/join-img.png"
                className="max-w-[500px] w-full h-auto"
                alt=""
              />
          </div>
              <p className="text-[15px] text-gray font-medium mb-2">
                This took me back to my teacher training days – what is the
                difference between and aim and an objective when planning
                lessons. The best explanation I was given is that the Aims (your
                Mission) was like the brick wall you hope to build.
              </p>
              <p className=" text-[15px] text-gray font-medium mb-2 ">
                The Objectives (your Aim) are how you are going to lay the
                bricks to build the wall. The trick is to avoid saying the same
                thing twice. Mission
              </p>
              <p className=" text-[15px] text-gray font-medium mb-2 ">
                To provide, co-ordinate, champion and develop innovative sport
                services and solutions to support young people and communities
                by being a positive alternative to the high risk and negative
                lifestyles that currently involve too many
              </p>
              <button type="button" className="btn-primary-outline mt-8">
                JOIN US{" "}
                <MdOutlineKeyboardDoubleArrowRight className="ms-3 text-[20px]" />
              </button>
            </div>
          </div>
          {/* <div className="club_img block  mt-8 ">
            <img
              src="/image/manchester/join-img.png"
              className="max-w-[500px] w-full h-auto"
              alt=""
            />
          </div> */}
        </div>
      </div>
      {/* SECTION TWO */}
      
    </div>
  );
};

export default OneToOne;
