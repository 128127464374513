import {React, useRef} from "react";
import Hero from "../element/HomeSection/Hero";
import AboutClub from "../element/HomeSection/AboutClub";
import PlayerStory from "../element/HomeSection/PlayerStory";
import AcademyLocation from "../element/HomeSection/AcademyLocation";
import Store from "../element/HomeSection/Store";
import Joinus from "../element/HomeSection/Joinus";
import Gallery from "../element/HomeSection/Gallery";
import Contact from "../element/HomeSection/Contact";
import Navbar from "../NavFooter/Navbar";
import ContactHeader from "../element/Contact/ContactHeader";
import NavbarTwo from "../NavFooter/NavbarTwo";


const ContactPage = () => {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div>
      <NavbarTwo variant={2}/>
      <ContactHeader/>
      <Contact location={'nottingham'}/>
    </div>
  );
};

export default ContactPage;