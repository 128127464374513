import React from 'react';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';


// Define a reusable TeamSection component
const TeamSection  = ({ bgColor, logoSrc, teamTitle, teamSrc, sponsor, division, accolades, coachName, coachTitle, coachSrc }) => (
  <main className={`py-[20px] md:py-[60px] md:px-[40px] px-[20px] bg-[${bgColor}]`}>
    <section className="w-full flex flex-col items-center justify-center gap-y-2">
      {/* <img src={logoSrc} className="max-w-[170px]" alt="" /> */}
      <h2 className="uppercase">{teamTitle}</h2>
    </section>
    <section className="lg:grid grid-cols-12 mt-6 max-w-[1100px] mx-auto">
      {/* MOBILE VIEW  */}
      <div className="col-span-8 lg:hidden block">
            <div>
              <img
                src={teamSrc}
                className="max-h-[350px] w-full object-cover"
                alt=""
              />
            </div>
            <div className="right-content mt-4 ">
              <div className=" max-w-[680px] w-full">
                <div className="flex items-center justify-between flex-wrap">
                <h3 className="uppercase text-black font-semibold lg:text-[30px] text-[25px]">
                SPONSOR : <span className="ps-2 text-red">{sponsor}</span>
              </h3>
              <h3 className="uppercase text-black font-semibold lg:text-[30px] text-[25px]">
                DIVISIONS : <span className="ps-2 text-red">{division}</span>
              </h3>
                </div>
                {/* <div className="flex items-center justify-between text-white md:text-[25px] sm:text-[20px] text-[16px] px-3 py-2 bg-red font-semibold  uppercase mt-4 max-w-[500px] w-full mx-auto">
                  <img
                    src="/image/ajfc/team/icon-1.png"
                    className="w-[20px] object-cover block me-4"
                    alt=""
                  />
                  <span className="">accolades :{" "} forest rec champions 2021</span>
                  <img
                    src="/image/ajfc/team/icon-2.png"
                    className="w-[20px] object-cover block "
                    alt=""
                  />
                </div> */}
                <Link
                // to="/contact" state={ {location: 'nottingham'}}
                to={{
                  pathname: '/contact',
                  state: { location: 'manchester', enquiry: 'player'} // Passing your props here
                }}>
                  <button
                    className="flex items-center justify-between text-white md:text-[25px] sm:text-[20px] text-[16px] px-5 py-2 bg-red font-medium  uppercase mt-4 max-w-[500px] w-full mx-auto text-[30px] transition duration-300 hover:bg-[#9c081c] hover:text-white focus:bg-[#9c081c]"
                  >
                    Join <MdOutlineKeyboardDoubleArrowRight className="ms-1 " />
                  </button>
                
                </Link>
              </div>
            </div>
          </div>
          {/* LEFT SECTION */}
      {/* Coach Info GONE*/}
      <div className="hidden md:block col-span-4 lg:mt-1 mt-8">
          <div className="hidden md:block team-card gradient max-w-[350px] w-full lg:max-h-[350px] h-[350px] lg:ms-auto pt-6">
              {/* <img
                src={coachSrc}
                className="w-[250px] lg:ms-auto absolute right-8 bottom-0"
                alt=""
              /> */}
          </div>
        {/* <div className="team-content lg:ps-3 mt-4 sm:text-start text-center">
          <h3 className="uppercase text-black font-bold text-[30px] leading-none">
            {coachName}
          </h3>
          <p className="text-gray font-primary uppercase">{coachTitle}</p>
        </div> */}
      </div>
      {/* Team Info */}
      <div className="hidden md:block col-span-8 lg:block">
        <div>
          <img src={teamSrc} className="max-h-[350px] w-full object-cover" alt="" />
        </div>
        <div className="right-content mt-4">
          <div className="max-w-[680px] w-full">
            <div className="flex items-center justify-between flex-wrap">
              <h3 className="uppercase text-black font-semibold lg:text-[30px] text-[25px]">
                SPONSOR : <span className="ps-2 text-red">{sponsor}</span>
              </h3>
              <h3 className="uppercase text-black font-semibold lg:text-[30px] text-[25px]">
                DIVISIONS : <span className="ps-2 text-red">{division}</span>
              </h3>
            </div>
            <Link
                // to="/contact" state={ {location: 'nottingham'}}
                to={{
                  pathname: '/contact',
                  state: { location: 'manchester', enquiry: 'player'} // Passing your props here
                }}>
                  <button
                    className="hidden md:flex flex items-center justify-between text-white md:text-[25px] sm:text-[20px] text-[16px] px-5 py-2 bg-red font-medium  uppercase mt-4 max-w-[500px] w-full mx-auto text-[30px] transition duration-300 hover:bg-[#9c081c] hover:text-white focus:bg-[#9c081c]"
                  >
                    Join <MdOutlineKeyboardDoubleArrowRight className="ms-1 " />
                  </button>
                
                </Link>
            {/* <div className="flex items-center justify-between text-white text-[25px] px-3 py-2 bg-red font-bold uppercase mt-4 max-w-[500px] w-full mx-auto">
            <img
                    src="/image/ajfc/team/icon-1.png"
                    className="w-[20px] object-cover block me-4"
                    alt=""
                  />
              <span className="hidden md:block ">accolades : {accolades}</span>
              <img
                    src="/image/ajfc/team/icon-1.png"
                    className="w-[20px] object-cover block me-4"
                    alt=""
                  />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default TeamSection;