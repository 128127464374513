import { BrowserRouter, Routes ,  Route } from "react-router-dom";
import Footer from './component/NavFooter/Footer';
import Home from './component/pages/Home';
import About from './component/pages/About';
import Store from './component/pages/Store';
import Staff from './component/pages/AJFC';
import Hospitality from './component/pages/Hospitality';
import London from './component/pages/London';
import ContactPage from "./component/pages/ContactPage";
import Manchester from "./component/pages/Manchester";
import Ajfc from "./component/pages/AJFC";
import ScrollToTop from "./ScrollToTop";
import Sponsors from "./component/pages/Sponsors";


function App() {
  return (
    <>
     <BrowserRouter>
     <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/store' element={<Store />} />
        <Route path='/AJFC' element={<Ajfc />} />
        <Route path='/hospitality' element={<Hospitality/>} />
        <Route path='/london' element={<London/>} />
        <Route path='/manchester' element={<Manchester/>}/>
        <Route path='/contact' element={<ContactPage/>}/>
        <Route path='/sponsors' element={<Sponsors/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
    
    </>
  );
}

export default App;
