import React from "react";
import NavbarTwo from "../NavFooter/NavbarTwo";
import AcademyHeader from "../element/AcademySection/AcademyHeader";
import JoinUs from "../element/AcademySection/JoinUs";
import Program from "../element/AcademySection/Program";
import AcademyStore from "../element/AcademySection/AcademyStore";
import Contact from "../element/HomeSection/Contact";

const London = () => {
  return (
    <>
      <NavbarTwo variant={2}/>
      <AcademyHeader />
      <JoinUs />
      <Program />
      <AcademyStore />
      <Contact location={'london'}/>
    </>
  );
};

export default London;
