import React from "react";
import NavbarTwo from "../NavFooter/NavbarTwo";
import Header from "../element/ManchesterSection/Header";
import SponsorsBody from "../element/Sponsors/SponsorsBody";
import SponsorHeader from "../element/Sponsors/Header";

const Sponsors = () => {
    return(
        <>
        <NavbarTwo variant={2}/>
        <SponsorHeader/>
        <SponsorsBody/> 
        </>
    )
}

export default Sponsors;