import React, { createRef, useRef } from 'react';
import AjfcHeader from "../element/AjfcSection/AjfcHeader";
import NavbarTwo from "../NavFooter/NavbarTwo";
import Category from "../element/AjfcSection/Category";
import Teams from "../element/AjfcSection/Teams";
import MenTeam from "../element/AjfcSection/MenTeam";

const Ajfc = () => {
  const teamRefs = useRef([...Array(12)].map(() => createRef())); // Adjust the array size based on your teams

  const scrollToTeam = (index) => {
    const ref = teamRefs.current[index];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <NavbarTwo variant={2} />
      <AjfcHeader />
      <Category scrollToTeam={scrollToTeam} />
      <Teams refs={teamRefs} />
      {/* <MenTeam /> */}
    </>
  );
};

export default Ajfc;
